<template>
	<div>
		<CRow>
			<CCol>
				<CCard>
					<CCardHeader>
						Permission List
					</CCardHeader>
					<CCardBody>
						<CButton class="mb-2 mr-2" @click="add" color="success"
							><CIcon name="cil-plus"
						/></CButton>
						<CButton class="mb-2 mr-2" @click="createAuto" color="success"
							><CIcon name="cil-plus" /> <span class="pt-1">AUTO</span></CButton
						>
						<CButton class="mb-2 mr-2" @click="refresh" color="info"
							><CIcon name="cil-reload"
						/></CButton>

						<CRow>
							<div
								class="position-relative table-responsive col-sm-6 tableFixHead"
							>
								<table class="table">
									<thead class="thead-dark">
										<tr>
											<th>#</th>
											<th>CODE</th>
											<th>TYPE</th>
											<th></th>
										</tr>
									</thead>
									<tbody class="position-relative">
										<tr v-for="(row, i) in rows" :key="i">
											<td>{{ i + 1 }}</td>
											<td>{{ row.code }}</td>
											<td>{{ row.type }}</td>
											<td>
												<CButton
													class="mb-1 mr-1"
													size="sm"
													@click="edit(i)"
													color="warning"
													><CIcon name="cil-pencil"
												/></CButton>
												<CButton
													class="mb-1 mr-1"
													size="sm"
													@click="confirmDelete(i)"
													color="danger"
													><CIcon name="cil-trash"
												/></CButton>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</CRow>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>

		<CModal
			:show.sync="modal"
			:no-close-on-backdrop="true"
			:centered="true"
			title="Modal title 2"
			size="lg"
			color="info"
		>
			<CRow>
				<CCol sm="6">
					<CInput label="Code" v-model="frm.code" placeholder="Enter Code" />
				</CCol>
				<CCol sm="6">
					<CSelect
						label="Type"
						:value.sync="frm.type"
						:options="['f', 'b']"
						placeholder="Please select"
					/>
				</CCol>
			</CRow>
			<template #header>
				<h6 class="modal-title">
					{{ selectedId != null ? "Edit " + frm.code : "Add new data" }}
				</h6>
				<CButtonClose @click="modal = false" class="text-white" />
			</template>
			<template #footer>
				<CButton @click="modal = false" color="danger">CANCEL</CButton>
				<CButton :disabled="loading" @click="create" color="success"
					>SAVE</CButton
				>
			</template>
		</CModal>
	</div>
</template>

<script>
export default {
	name: "Access",
	components: {},
	data() {
		return {
			modal: false,
			frm: {
				code: "",
				type: "",
			},
			selectedId: null,
			selectedIndex: null,
			loading: false,
			rows: [],
		};
	},
	mounted() {
		this.getAccess();
	},
	methods: {
		async getAccess() {
			this.rows = [];
			try {
				const { data } = await this.$http.get("acl/all_access", {
					params: { page: 1, per_page: 3000 },
				});
				this.rows = data.data.data;
			} catch (err) {
				this.$toast.error(err.message);
			}
		},
		refresh() {
			this.getAccess();
		},
		add() {
			this.selectedId = null;
			this.frm = {
				code: "",
				type: "",
			};
			this.modal = true;
		},
		async create() {
			this.loading = true;

			try {
				let datar = {};
				if (this.selectedId) {
					const { data } = await this.$http.put("acl/access", this.frm, {
						params: { id: this.selectedId },
					});
					datar = data;
				} else {
					const { data } = await this.$http.post("acl/access", this.frm);
					datar = data;
				}
				if (datar.status == "success") {
					this.$toast.success("Success ! " + datar.message);
					this.refresh();
					this.modal = false;
				} else {
					this.$swal("Fail !", datar.message, "danger");
				}
			} catch (error) {
				this.$swal("Error !", error.message, "danger");
			}
			this.loading = false;
		},
		async createAuto() {
			try {
				const { data } = await this.$http.get("acl/auto_access");
				if (data.status == "success") {
					this.$toast.success("Success ! " + data.message);
					this.refresh();
				} else {
					this.$swal("Fail !", data.message, "danger");
				}
			} catch (error) {
				this.$swal("ERROR !", error.message, "danger");
			}
		},
		confirmDelete(i) {
			const vm = this;
			vm.selectedId = vm.rows[i].id;

			vm.$swal({
				title: "Are you sure?",
				text: "You won't be able to revert this! " + vm.frm.code,
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3bb858",
				cancelButtonColor: "#d33",
				confirmButtonText: "Yes, delete it!",
			}).then(async (result) => {
				if (result.isConfirmed) {
					try {
						const { data } = await vm.$http.delete("acl/access", {
							params: { id: vm.selectedId },
						});
						if (data.status == "success") {
							vm.$toast.success("Success ! " + data.message);
							vm.refresh();
						} else {
							vm.$swal("Fail !", data.message, "danger");
						}
					} catch (error) {
						vm.$swal("Error !", error.message, "danger");
					}
				}
			});
		},
		edit(i) {
			this.frm = this.rows[i];
			this.selectedId = this.frm.id;
			this.modal = true;
		},
	},
};
</script>

<style lang="css" scoped>
.tableFixHead {
	overflow: auto;
	height: 500px;
}
.tableFixHead thead th {
	position: sticky;
	top: 0;
	z-index: 100;
}
</style>
